<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :footer-props="{
      'items-per-page-options': [pagination.rowsPerPage],
    }"
    :headers="tableMixin_displayedHeaders"
    :mobile-breakpoint="0"
    :page="pagination.page"
    :server-items-length="pagination.totalItems"
    :loading="loading"
    :items="rows"
    :height="height"
    :fixed-header="fixedHeader"
    :dense="dense"
    disable-sort
    @click:row="tableMixin_onRowClick"
    @update:page="tableMixin_changePage"
  >
    <template v-slot:item.order_part_no="{ item }">
      <a
        v-if="item.order"
        :href="`${ordersUrl}?search=${item.order.order_no}&orderPartId=${item.id}`"
        class="link"
        target="_blank"
        >{{ item.order.order_no }}.{{ item.order_part_no }}</a
      >
    </template>

    <template v-slot:item.client_contact_id="{ item }">
      <v-menu
        v-if="item.order && item.order.client"
        :close-on-content-click="false"
        offset-y
        nudge-bottom="8"
      >
        <template v-slot:activator="{ on }">
          <span v-on="on" class="link">
            {{ item.order.client.name }}
          </span>
        </template>
        <ContactCard :contact-id="item.order.client.id" />
      </v-menu>
    </template>

    <template v-slot:item.status="{ item }">
      <v-chip
        :color="processStatuses[item.status].color"
        class="persist-opacity font-weight-medium"
        label
        small
      >
        {{ $t(item.status) }}
      </v-chip>
    </template>

    <template v-slot:expanded-item="{ item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.client_contact_id>
          <v-menu
            v-if="item.order && item.order.client"
            :close-on-content-click="false"
            offset-y
            nudge-bottom="8"
          >
            <template v-slot:activator="{ on }">
              <span v-on="on" class="link">
                {{ item.order.client.name }}
              </span>
            </template>
            <ContactCard :contact-id="item.order.client.id" />
          </v-menu>
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import tableMixin from '../../mixins/table-mixin';
import { getOrderPartProcessStatusMap } from '@/api/order-part-service';
import ContactCard from '@/components/ContactCard';

export default {
  name: 'OrderPartTable',

  components: {
    ContactCard,
    BaseExpandedTableRow,
  },

  mixins: [tableMixin],

  props: {
    rows: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fixedHeader: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [Number, String],
      default: '',
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      processStatuses: getOrderPartProcessStatusMap(),
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('order_part_no'),
          value: 'order_part_no',
        },
        {
          text: this.$t('client_contact_id'),
          value: 'client_contact_id',
          hidden: 'xs',
        },
        {
          text: this.$t('status'),
          value: 'status',
        },
      ];
    },

    ordersUrl() {
      return `${process.env.VUE_APP_PUBLIC_PATH}orders`;
    },
  },
};
</script>
