<template>
  <v-menu
    v-if="!disabled"
    v-model="isMenuOpen"
    :close-on-content-click="false"
    :left="left"
    offset-y
  >
    <template v-slot:activator="{ on: menu }">
      <v-tooltip :disabled="!tooltip" bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-chip
            v-on="{ ...menu, ...tooltip }"
            :color="color || selectedItem.color"
            :class="chipClasses"
            :outlined="outlined"
            :text-color="textColor"
            :close="clearable && !!value"
            small
            @click.stop
            @click:close="$emit('change', null)"
          >
            {{ selectedItem[itemText] || noDataText }}
          </v-chip>
        </template>
        <span style="white-space: pre-line">
          {{ tooltip }}
        </span>
      </v-tooltip>
    </template>
    <v-sheet>
      <template v-if="showSearch">
        <v-text-field
          v-model="query"
          :label="$t('search')"
          class="px-3 py-3 d-flex align-center"
          clearable
          dense
          hide-details
          outlined
          single-line
        />
        <v-divider />
      </template>
      <v-list max-height="300" class="overflow-y-auto" dense>
        <v-list-item v-if="!filteredItems.length" key="no-results">
          <v-list-item-title class="grey--text">
            {{ $t('no_results') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="item in filteredItems"
          :key="item[itemValue]"
          @click="onItemSelect(item)"
        >
          <v-list-item-title>
            {{ item[itemText] }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-menu>
  <v-chip
    v-else
    :color="color || selectedItem.color"
    :class="chipClasses"
    :outlined="outlined"
    :text-color="textColor"
    :close="clearable"
    small
    label
    @click:close="$emit('change', null)"
  >
    {{ selectedItem[itemText] || noDataText }}
  </v-chip>
</template>

<script>
import i18n from '@/i18n/i18n-config';

export default {
  name: 'BaseChipSelect',

  props: {
    model: {
      prop: 'value',
      event: 'change',
    },
    value: {
      type: [String, Number],
    },
    items: {
      type: Array,
      required: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
    chipClasses: {
      type: String,
      default: 'my-1',
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    color: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
    noDataText: {
      type: String,
      default: i18n.t('not_set'),
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isMenuOpen: false,
      query: '',
    };
  },

  computed: {
    selectedItem() {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i][this.itemValue] === this.value) {
          return this.items[i];
        }
      }
      return {};
    },

    filteredItems() {
      if (!this.query) {
        return this.items;
      }
      const q = this.query.toLowerCase().trim();
      return this.items.filter(i => i[this.itemText].toLowerCase().indexOf(q) > -1);
    },
  },

  methods: {
    onItemSelect(item) {
      if (item[this.itemValue] !== this.value) {
        this.$emit('change', item[this.itemValue]);
      }
      this.isMenuOpen = false;
      setTimeout(() => {
        this.query = '';
      }, 300);
    },
  },
};
</script>

<style scoped></style>
