<template>
  <BaseChipSelect
    v-if="canEdit"
    :value="process.status"
    :items="processStatuses"
    @change="$emit('change', $event)"
  />
  <v-chip
    v-else
    :color="processStatusesMap[process.status].color"
    class="persist-opacity font-weight-medium"
    label
    small
  >
    {{ processStatusText }}
    <template v-if="showProgress">{{ process.quantity }}/{{ process.planned_quantity }}</template>
  </v-chip>
</template>

<script>
import {
  getOrderPartProcessStatusMap,
  ORDER_PART_PROCESS_STATUSES,
} from '@/api/order-part-service';
import BaseChipSelect from '@/components/base/BaseChipSelect';
import i18n from '@/i18n/i18n-config';

export default {
  name: 'OrderPartProcessStatusChip',

  components: { BaseChipSelect },

  props: {
    process: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    processStatuses() {
      const statuses = [];
      for (let i = 0; i < ORDER_PART_PROCESS_STATUSES.length; i++) {
        if (this.process.quantity > 0 && ORDER_PART_PROCESS_STATUSES[i].value === 'not_started') {
          statuses.push({
            ...ORDER_PART_PROCESS_STATUSES[i],
            text: i18n.t('process_paused'),
            buttonText: 'continue',
          });
        } else {
          statuses.push(ORDER_PART_PROCESS_STATUSES[i]);
        }
      }
      return statuses;
    },

    processStatusesMap() {
      return getOrderPartProcessStatusMap();
    },

    processStatusText() {
      if (this.process.status === 'not_started' && this.process.quantity > 0) {
        return this.$t('process_paused');
      }

      return this.$t(this.process.status);
    },
  },
};
</script>

<style scoped></style>
