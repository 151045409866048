import http from './http';

const endpoint = 'api/machineries';

const machineryService = {
  model: 'machinery',

  getPage: (page, params) => http.get(endpoint, { params: { ...params, page } }),

  search: query => http.get(`${endpoint}/find/${query}`),

  create: machinery => http.post(`${endpoint}`, machinery),

  update: machinery => http.put(`${endpoint}/${machinery.id}`, machinery),

  updateStatus: machinery => http.patch(`${endpoint}/${machinery.id}`, machinery),

  delete: machinery => http.delete(`${endpoint}/${machinery.id}`),
};

export default machineryService;

export const MACHINERY_STATUSES = [
  {
    text: 'machinery_statuses.maintenance',
    value: 'maintenance',
    color: 'red lighten-4',
  },
  {
    text: 'machinery_statuses.malfunction',
    value: 'malfunction',
    color: 'yellow lighten-4',
  },
  {
    text: 'machinery_statuses.operational',
    value: 'operational',
    color: 'green accent-1',
  },
  {
    text: 'machinery_statuses.retired',
    value: 'retired',
    color: 'grey lighten-2',
  },
];

export const getMachineryStatusMap = () => {
  const map = {};
  MACHINERY_STATUSES.forEach(status => {
    map[status.value] = { ...status };
  });
  return map;
};
