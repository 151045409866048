<template>
  <BaseTableLoader :loading="loading">
    <v-data-table
      :expanded="tableMixin_expandedRows"
      :headers="tableMixin_displayedHeaders"
      :items="displayedItems"
      :mobile-breakpoint="0"
      disable-pagination
      disable-sort
      hide-default-footer
      @click:row="tableMixin_onRowClick"
    >
      <template v-slot:item.title="{ item }">
        <v-menu :close-on-content-click="false" offset-y nudge-bottom="8">
          <template v-slot:activator="{ on }">
            <span
              v-on="on"
              :class="{ 'grey--text': !item.is_active && $vuetify.breakpoint.xsOnly }"
              class="link"
            >
              {{ item.title }}
            </span>
          </template>
          <ProcessCard :process-id="item.id" />
        </v-menu>
        <div v-if="item.is_support_process" class="grey--text">{{ $t('is_support_process') }}</div>
      </template>

      <template v-slot:item.statusText="{ item }">
        <v-chip :color="item.color" class="caption persist-opacity" label small>
          {{ item.statusText }}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <BaseActionMenu :actions="actions" :item="item" />
      </template>

      <template v-slot:expanded-item="{ item }">
        <BaseExpandedTableRow
          :colspan="tableMixin_displayedHeaders.length"
          :headers="tableMixin_hiddenHeaders"
          :item="item"
        >
          <template v-slot:item.statusText>
            <v-chip :color="item.color" class="caption" label small>
              {{ item.statusText }}
            </v-chip>
          </template>
        </BaseExpandedTableRow>
      </template>
    </v-data-table>
  </BaseTableLoader>
</template>

<script>
import BaseActionMenu from '../base/BaseActionMenu';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import BaseTableLoader from '../base/BaseTableLoader';
import tableMixin from '../../mixins/table-mixin';
import ProcessCard from '../ProcessCard';

export default {
  name: 'ProcessTable',

  components: {
    ProcessCard,
    BaseTableLoader,
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    rows: Array,
    pagination: Object,
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      actions: [
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
      ],
      headers: [
        {
          text: this.$t('title'),
          value: 'title',
        },
        {
          text: this.$t('group'),
          value: 'groupName',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('status'),
          value: 'statusText',
          hidden: 'xsOnly',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ],
    };
  },

  computed: {
    displayedItems() {
      return this.rows.map(r => {
        let color;
        let statusText;
        if (r.is_active) {
          color = 'green accent-1';
          statusText = this.$t('active');
        } else {
          color = 'grey lighten-4';
          statusText = this.$t('inactive');
        }
        return {
          ...r,
          groupName: r.group?.name,
          color,
          statusText,
        };
      });
    },
  },
};
</script>
