<template>
  <div class="page-wrapper">
    <v-tabs
      v-model="activeTab"
      @change="onTabChange"
    >
      <v-tab>{{ $t('processes') }}</v-tab>
      <v-tab>{{ $t('groups') }}</v-tab>
      <v-tab-item>
        <div class="d-flex justify-space-between px-3">
          <v-text-field
            v-model="processSearch"
            :label="$t('search')"
            prepend-inner-icon="mdi-magnify"
            style="max-width: 300px"
            clearable
            hide-details
            single-line
          />
          <BasePrimaryActionButton
            :label="$t('create_process')"
            @click="crudMixin_openForm('process', newProcessTemplate)"
          />
        </div>
        <ProcessTable
          :rows="filteredProcesses"
          :loading="isDataLoading"
          @edit="crudMixin_openForm('process', $event)"
        />
      </v-tab-item>
      <v-tab-item>
        <div class="d-flex justify-space-between px-3">
          <v-text-field
            v-model="processGroupSearch"
            :label="$t('search')"
            prepend-inner-icon="mdi-magnify"
            style="max-width: 300px"
            clearable
            hide-details
            single-line
          />
          <BasePrimaryActionButton
            :label="$t('create_process_group')"
            @click="crudMixin_openForm('processGroup', newProcessTemplate)"
          />
        </div>
        <ProcessGroupTable
          :rows="filteredProcessGroups"
          :loading="areProcessGroupsLoading"
          @edit="crudMixin_openForm('processGroup', $event)"
        />
      </v-tab-item>
    </v-tabs>

    <v-dialog
      v-model="isProcessFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <ProcessForm
        :dialog="isProcessFormOpen"
        :form-item="processFormItem"
        :process-groups="processGroupArray"
        @create="crudMixin_created('process', $event)"
        @update="crudMixin_updated('process', $event)"
        @cancel="isProcessFormOpen = false"
      />
    </v-dialog>

    <v-dialog
      v-model="isProcessGroupFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <ProcessGroupForm
        :dialog="isProcessGroupFormOpen"
        :form-item="processGroupFormItem"
        @create="crudMixin_created('processGroup', $event)"
        @update="crudMixin_updated('processGroup', $event)"
        @cancel="isProcessGroupFormOpen = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import ProcessForm from '../components/forms/ProcessForm';
import ProcessTable from '../components/tables/ProcessTable';
import crudMixin from '../mixins/crud-mixin';
import processService from '../api/process-service';
import BasePrimaryActionButton from '../components/base/BasePrimaryActionButton';
import processGroupService from '../api/process-group-service';
import ProcessGroupTable from '../components/tables/ProcessGroupTable';
import ProcessGroupForm from '../components/forms/ProcessGroupForm';

const TABS = ['list', 'groups'];
const DEFAULT_TAB_INDEX = 0;

export default {
  name: 'Processes',

  components: {
    ProcessGroupForm,
    ProcessGroupTable,
    BasePrimaryActionButton,
    ProcessForm,
    ProcessTable,
  },

  mixins: [crudMixin],

  data() {
    return {
      activeTab: null,
      areProcessGroupsLoading: true,
      isDataLoading: true,
      isProcessFormOpen: false,
      isProcessGroupFormOpen: false,
      newProcessTemplate: {
        is_active: true,
      },
      processArray: [],
      processFilterParams: '',
      processFormItem: {},
      processGroupArray: [],
      processGroupFormItem: {},
      processGroupSearch: '',
      processSearch: '',
    };
  },

  created() {
    this.setActiveTab(this.$route.params.tab);
    this.getProcesses();
    this.getProcessGroups();
  },

  beforeRouteUpdate(to, from, next) {
    this.setActiveTab(to.params.tab);
    next();
  },

  computed: {
    filteredProcesses() {
      if (!this.processSearch) {
        return this.processArray;
      }
      const search = this.processSearch.toLowerCase();
      return this.processArray.filter(p => p.title.toLowerCase().trim().indexOf(search) > -1);
    },

    filteredProcessGroups() {
      if (!this.processGroupSearch) {
        return this.processGroupArray;
      }
      const search = this.processGroupSearch.toLowerCase();
      return this.processGroupArray.filter(p => p.name.toLowerCase().trim().indexOf(search) > -1);
    },
  },

  methods: {
    getProcesses() {
      this.crudMixin_getAll(
        processService.getAll,
        processService.model,
        this.processFilterParams,
      );
    },

    getProcessGroups() {
      processGroupService.getAll().then((res) => {
        // filters out the group that holds ungrouped processes
        this.processGroupArray = res.data.filter(group => group.id > 0);
      }).finally(() => {
        this.areProcessGroupsLoading = false;
      });
    },

    onTabChange(newIndex) {
      this.$router.push(`/processes/${TABS[newIndex]}`);
    },

    setActiveTab(tabName) {
      const activeTabIndex = TABS.indexOf(tabName);
      this.activeTab = activeTabIndex > -1 ? activeTabIndex : DEFAULT_TAB_INDEX;
    },
  },
};
</script>
