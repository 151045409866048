<template>
  <v-fade-transition hide-on-leave>
    <template v-if="loading">
      <v-skeleton-loader
        v-if="$vuetify.breakpoint.smAndUp"
        type="table"
      />
      <div v-else>
        <v-skeleton-loader
          class="px-3 py-4"
          type="heading"
        />
        <v-skeleton-loader
          class="px-3"
          type="table-cell"
        />
        <v-skeleton-loader
          v-for="i in mobileRowCount"
          :key="i"
          class="pa-3"
          type="text"
        />
      </div>
    </template>
    <slot v-else></slot>
  </v-fade-transition>
</template>

<script>
export default {
  name: 'BaseTableLoader',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      mobileRowCount: 5,
    };
  },
};
</script>

<style scoped>

</style>
