<template>
  <v-tooltip
    :content-class="inDialog ? 'tooltip-in-dialog' : ''"
    :max-width="maxWidth"
    transition="none"
    bottom
  >
    <template #activator="{ on }">
      <v-icon v-bind="$attrs" v-on="on" :class="iconClass" color="info">mdi-information</v-icon>
    </template>
    <div class="text-pre-line">{{ text }}</div>
  </v-tooltip>
</template>

<script>
export default {
  name: 'BaseInfoTooltip',

  props: {
    text: {
      type: String,
      required: true,
      default: '',
    },
    inDialog: {
      // fixes tooltip z-index, so it appears over the dialog
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: '35em',
    },
    iconClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped></style>
