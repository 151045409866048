<template>
  <v-card :loading="loading" width="300">
    <v-card-title class="d-flex justify-space-between align-center">
      {{ contact.name }}
    </v-card-title>
    <v-card-text>
      <v-row no-gutters>
        <v-col :cols="contact.logo_file_url ? 9 : 12">
          <p v-if="contact.website">
            {{ $t('website') }}&nbsp;<br />
            <a :href="contact.website" class="link" target="_blank">
              {{ contact.website }}
            </a>
          </p>
          <p v-if="contact.address">
            {{ $t('address') }}&nbsp;<br />
            <a :href="`https://maps.google.com/?q=${contact.address}`" class="link" target="_blank">
              {{ contact.address }}
            </a>
          </p>
        </v-col>
        <v-col v-if="contact.logo_file_url" cols="3">
          <v-img :src="contact.logo_file_url" position="right" width="100%" contain />
        </v-col>
      </v-row>
      <template v-if="contact.persons && contact.persons.length">
        <v-divider />
        <p class="mb-0 mt-3">{{ $t('contact_persons') }}</p>
        <v-list class="pa-0">
          <v-list-item v-for="person in contact.persons" :key="person.id" class="pa-0">
            <v-list-item-content>
              <v-list-item-title>
                {{ person.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <div>
                  <a :href="`mailto:${person.email}`" class="link">
                    {{ person.email }}
                  </a>
                </div>
                <div>
                  <a :href="`tel:${person.phone}`" class="link">
                    {{ person.phone }}
                  </a>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip v-if="person.comment" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-comment</v-icon>
                </template>
                <span class="text-preserve-whitespace">{{ person.comment }}</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import contactService from '../api/contact-service';

export default {
  name: 'ContactCard',

  props: {
    contactId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      contact: {},
    };
  },

  created() {
    contactService
      .getById(this.contactId)
      .then(res => {
        this.contact = res.data;
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped></style>
