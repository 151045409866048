<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="process.title"
              :error-messages="errors['title']"
              :label="formMixin_getRequiredFieldLabel($t('title'))"
              @blur="formMixin_clearErrors('title')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="process.process_group_id"
              :error-messages="errors['process_group_id']"
              :items="processGroups"
              :label="$t('group')"
              item-text="name"
              item-value="id"
              clearable
              @change="formMixin_clearErrors('process_group_id')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-checkbox
              v-model="process.is_active"
              :error-messages="errors['is_active']"
              :label="$t('is_active')"
              @blur="formMixin_clearErrors('is_active')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-checkbox
              v-model="process.is_support_process"
              :error-messages="errors['is_support_process']"
              @blur="formMixin_clearErrors('is_support_process')"
            >
              <template v-slot:label>
                <div class="d-flex align-center">
                  <span>{{ $t('is_support_process') }}</span>
                  <BaseInfoTooltip
                    :text="$t('is_support_process_hint')"
                    max-width="unset"
                    icon-class="ml-2"
                    in-dialog
                  />
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!process.id" class="subtitle-2 ml-3"> * {{ $t('must_be_filled') }} </span>

        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import processService from '../../api/process-service';
import BaseInfoTooltip from '@/components/base/BaseInfoTooltip';

export default {
  name: 'ProcessForm',

  components: { BaseInfoTooltip },

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: Object,
    processGroups: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      process: {},
    };
  },

  computed: {
    formTitle() {
      return this.$t(this.process.id ? 'edit_process' : 'new_process');
    },
  },

  methods: {
    onDialogOpen() {
      this.process = JSON.parse(JSON.stringify(this.formItem));
      this.errors = {};
    },

    onSubmit() {
      this.process.process_group_id = this.process.process_group_id || null;
      this.crudMixin_createOrUpdate(processService, this.process);
    },
  },
};
</script>
