import http from './http';

const endpoint = 'api/process-groups';

const processGroupService = {
  model: 'processGroup',

  getAll: () => http.get(endpoint),

  create: processGroup => http.post(endpoint, processGroup),

  update: processGroup => http.put(`${endpoint}/${processGroup.id}`, processGroup),

  delete: processGroup => http.delete(`${endpoint}/${processGroup.id}`, processGroup),
};

export default processGroupService;
