<template>
  <v-card class="overflow-y-hidden">
    <v-progress-circular v-if="loadingData" color="primary" class="ma-3" indeterminate />
    <template v-else>
      <h2 v-if="process.title" class="subtitle-2 py-3 px-4">
        {{ process.title }}
      </h2>
      <v-tabs v-model="activeTab" show-arrows>
        <v-tab v-if="showMachineriesTab">
          {{ $t('machineries') }}
        </v-tab>
        <v-tab v-if="showUnfinishedProcessesTab">
          {{ $t('unfinished_processes') }}
        </v-tab>
        <v-tab v-if="showProgressTab">
          {{ $t('progress') }}
        </v-tab>
        <v-tab v-if="showStatusChangelogTab">
          {{ $t('status_changelog') }}
        </v-tab>
        <v-tab v-if="showIncludedInOrdersTab">
          {{ $t('included_in_orders') }}
        </v-tab>

        <v-tab-item v-if="showMachineriesTab">
          <div v-if="!process.machineries.length" class="pa-3 grey--text">
            {{ $t('process_has_no_assigned_machinery') }}
          </div>
          <MachineryList v-else :machineries="process.machineries" inactive />
        </v-tab-item>

        <v-tab-item v-if="showUnfinishedProcessesTab">
          <div v-if="!process.active_order_part_processes.length" class="pa-3 grey--text">
            {{ $t('no_unfinished_processes') }}
          </div>
          <v-list v-else dense max-height="300px" class="overflow-y-auto">
            <v-list-item
              v-for="(activeProcess, index) in process.active_order_part_processes"
              :key="activeProcess.id"
              :class="{ 'border-t': index > 0 }"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <router-link
                    v-if="activeProcess.order_part && activeProcess.order_part.order"
                    :to="getOrderUrl(activeProcess.order_part.order)"
                    class="link"
                  >
                    {{ activeProcess.order_part.order.order_no }}
                  </router-link>
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="activeProcess.order_part && activeProcess.order_part.inventory_item"
                >
                  {{ activeProcess.order_part.inventory_item.title }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content
                v-if="activeProcess.planned_quantity && activeProcess.estimated_duration_in_minutes"
              >
                <div class="subtitle-2 mb-0">{{ $t('estimated_duration') }}:</div>
                <div class="body-2">{{ getEstimatedTotalDuration(activeProcess) }}</div>
              </v-list-item-content>
              <v-list-item-action>
                <OrderPartProcessStatusChip :process="activeProcess" show-progress />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-tab-item>

        <v-tab-item v-if="showProgressTab">
          <div v-if="!workerProgress.length" class="pa-3 grey--text">
            {{ $t('no_data') }}
          </div>
          <v-list v-else dense max-height="300px" class="overflow-y-auto">
            <v-list-item v-for="progress in workerProgress" :key="progress.id">
              <v-list-item-content>
                <v-list-item-title class="d-flex justify-space-between">
                  <div>
                    <span class="grey--text mr-2">{{ progress.date }}</span>
                    {{ progress.user.person.full_name }}
                  </div>
                  <div>
                    {{ progress.amount }}
                    {{ $t(`${progress.pcs === 'minutes' ? 'minute' : 'unit'}_short`) }}
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-tab-item>

        <v-tab-item v-if="showStatusChangelogTab">
          <div v-if="!statusChangelog.length" class="pa-3 grey--text">
            {{ $t('no_data') }}
          </div>
          <v-list v-else dense max-height="300px" class="overflow-y-auto">
            <v-list-item v-for="log in transformedStatusChangelog" :key="log.id">
              <v-list-item-content>
                <v-list-item-title class="d-flex justify-space-between align-center">
                  <div>
                    <span class="grey--text mr-2">{{ log.date }}</span
                    ><br v-if="$vuetify.breakpoint.xsOnly" />
                    {{ log.userFullName }}
                  </div>
                  <OrderPartProcessStatusChip
                    :process="{ status: log.newStatus, quantity: log.quantity }"
                  />
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-tab-item>

        <v-tab-item v-if="showIncludedInOrdersTab">
          <OrderPartTable
            :rows="processOrderPartArray"
            :pagination="processOrderPartPagination"
            :loading="$store.getters.loading[`get:api/processes/${processId}/order-parts`]"
            height="287px"
            fixed-header
            dense
            @change-page="getProcessOrderParts"
          />
        </v-tab-item>
      </v-tabs>
    </template>
  </v-card>
</template>

<script>
import { format } from 'date-fns';
import processService, { getEstimatedTotalDuration } from '../api/process-service';
import orderPartService from '../api/order-part-service';
import crudMixin from '@/mixins/crud-mixin';
import OrderPartTable from '@/components/tables/OrderPartTable';
import MachineryList from '@/components/MachineryList';
import OrderPartProcessStatusChip from '@/components/OrderPartProcessStatusChip';

export default {
  name: 'ProcessCard',

  components: { OrderPartProcessStatusChip, MachineryList, OrderPartTable },

  mixins: [crudMixin],

  props: {
    processId: {
      type: Number,
      required: true,
    },
    orderPartProcessId: {
      type: Number,
      default: 0,
    },
    displayedTabs: {
      type: Array,
      default: () => [
        'machineries',
        'unfinishedProcesses',
        'progress',
        'statusChangelog',
        'includedInOrders',
      ],
    },
  },

  data() {
    return {
      activeTab: 0,
      process: {
        machineries: [],
        active_order_part_processes: [],
      },
      processOrderPartArray: [],
      processOrderPartPagination: {},
      workerProgress: [],
      statusChangelog: [],
    };
  },

  computed: {
    transformedStatusChangelog() {
      return this.statusChangelog.map(log => ({
        date: format(new Date(log.created_at), 'yyyy-MM-dd HH:mm'),
        userFullName: log.user?.person?.full_name,
        newStatus: log.status,
      }));
    },

    loadingData() {
      const { loading } = this.$store.getters;
      return (
        loading[`get:api/processes/${this.processId}`] ||
        loading[`get:api/order-part-processes/${this.orderPartProcessId}/all-progress`]
      );
    },

    showMachineriesTab() {
      return this.displayedTabs.includes('machineries');
    },

    showUnfinishedProcessesTab() {
      return this.displayedTabs.includes('unfinishedProcesses');
    },

    showProgressTab() {
      return this.displayedTabs.includes('progress') && this.orderPartProcessId;
    },

    showStatusChangelogTab() {
      return this.displayedTabs.includes('statusChangelog') && this.orderPartProcessId;
    },

    showIncludedInOrdersTab() {
      return this.displayedTabs.includes('includedInOrders') && !this.orderPartProcessId;
    },
  },

  created() {
    if (this.$isAdmin()) {
      processService.getById(this.processId).then(res => {
        this.process = res.data;
      });
    }

    if (this.showIncludedInOrdersTab) {
      this.getProcessOrderParts(1);
    }

    if (this.showProgressTab && this.orderPartProcessId) {
      orderPartService.getAllWorkerProcessProgress(this.orderPartProcessId).then(res => {
        this.workerProgress = res.data;
      });
    }

    if (this.showStatusChangelogTab && this.orderPartProcessId) {
      orderPartService.getProcessChangelog(this.orderPartProcessId).then(res => {
        this.statusChangelog = res.data.data;
      });
    }
  },

  methods: {
    getEstimatedTotalDuration,

    getProcessOrderParts(page = 1) {
      this.crudMixin_getPage(
        processService.getProcessOrderParts,
        'processOrderPart',
        page,
        this.processId,
      );
    },

    getOrderUrl(order) {
      return `/orders?search=${order.order_no}`;
    },
  },
};
</script>

<style scoped></style>
