import http from './http';
import i18n from '@/i18n/i18n-config';

const endpoint = 'api/processes';

const processService = {
  model: 'process',

  getAll: query => http.get(`${endpoint}?${query}`),

  getById: id => http.get(`${endpoint}/${id}`),

  getMachineryAvailability: id => http.get(`${endpoint}/${id}/machinery-availability`),

  getProcessOrderParts: (page, id) =>
    http.get(`${endpoint}/${id}/order-parts`, { params: { page } }),

  create: process => http.post(`${endpoint}`, process),

  update: process => http.put(`${endpoint}/${process.id}`, process),
};

export default processService;

export function getEstimatedTotalDuration(process) {
  if (!+process.planned_quantity || !+process.estimated_duration_in_minutes) {
    return '';
  }

  // used to be minutes, but was later changed to seconds, variable name in backend was kept the same
  let seconds = +process.planned_quantity * +process.estimated_duration_in_minutes;

  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;

  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  let durationString = '';

  if (hours) {
    durationString += `${hours} ${i18n.t('hours_short')}, `;
  }

  if (minutes) {
    durationString += `${minutes} ${i18n.t('minutes_short')}, `;
  }

  if (seconds) {
    durationString += `${seconds} ${i18n.t('seconds_short')}, `;
  }

  return durationString.slice(0, -2);
}
