var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTableLoader',{attrs:{"loading":_vm.loading}},[_c('v-data-table',{attrs:{"expanded":_vm.tableMixin_expandedRows,"headers":_vm.tableMixin_displayedHeaders,"items":_vm.displayedItems,"mobile-breakpoint":0,"disable-pagination":"","disable-sort":"","hide-default-footer":""},on:{"click:row":_vm.tableMixin_onRowClick},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","nudge-bottom":"8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"link",class:{ 'grey--text': !item.is_active && _vm.$vuetify.breakpoint.xsOnly }},on),[_vm._v(" "+_vm._s(item.title)+" ")])]}}],null,true)},[_c('ProcessCard',{attrs:{"process-id":item.id}})],1),(item.is_support_process)?_c('div',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.$t('is_support_process')))]):_vm._e()]}},{key:"item.statusText",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"caption persist-opacity",attrs:{"color":item.color,"label":"","small":""}},[_vm._v(" "+_vm._s(item.statusText)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.actions,"item":item}})]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.statusText",fn:function(){return [_c('v-chip',{staticClass:"caption",attrs:{"color":item.color,"label":"","small":""}},[_vm._v(" "+_vm._s(item.statusText)+" ")])]},proxy:true}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }