<template>
  <v-list dense>
    <v-list-item
      v-for="machinery in machineries"
      :key="machinery.id"
      :inactive="inactive"
      :ripple="!inactive"
      :class="{ 'clickable-list-item': !inactive }"
      @click="$emit('click:row', machinery)"
    >
      <v-list-item-content>
        <v-list-item-title :class="{ 'primary--text': !inactive }">{{
          machinery.name
        }}</v-list-item-title>
        <v-list-item-subtitle>
          {{ getMachineryBusinessText(machinery.busy_until) }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-chip
          :color="machineryStatusMap[machinery.status].color"
          class="persist-opacity"
          label
          small
        >
          {{ $t(machineryStatusMap[machinery.status].text) }}
        </v-chip>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { getMachineryStatusMap } from '@/api/machinery-service';

export default {
  name: 'MachineryList',

  props: {
    machineries: {
      type: Array,
      default: () => [],
    },
    inactive: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    machineryStatusMap() {
      return getMachineryStatusMap();
    },
  },

  methods: {
    getMachineryBusinessText(busyUntil) {
      if (!busyUntil) {
        return this.$t('machinery_not_busy');
      }
      return `${this.$t('machinery_busy_until')}: ${busyUntil}`;
    },
  },
};
</script>
