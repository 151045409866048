<template>
  <td :colspan="colspan">
    <slot></slot>
    <div class="py-2">
      <v-row>
        <v-col v-for="header in headers" :key="header.value" cols="12" sm="6" md="4" lg="3">
          <span class="subtitle-2">
            {{ header.text }}
          </span>
          <br />
          <slot :name="`item.${header.value}`">
            <span v-if="$getProperty(item, header.value)">{{
              $getProperty(item, header.value)
            }}</span>
            <span v-else>
              -
            </span>
          </slot>
        </v-col>
      </v-row>
    </div>
    <slot name="append"></slot>
  </td>
</template>

<script>
export default {
  name: 'BaseExpandedTableRow',

  props: {
    headers: {
      type: Array,
      default: () => [],
    },

    colspan: {
      type: Number,
      default: 1,
    },

    item: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped></style>
