<template>
  <BaseTableLoader :loading="loading">
    <v-data-table
      :expanded="tableMixin_expandedRows"
      :headers="tableMixin_displayedHeaders"
      :items="rows"
      :mobile-breakpoint="0"
      disable-pagination
      disable-sort
      hide-default-footer
      @click:row="tableMixin_onRowClick"
    >
      <template v-slot:item.actions="{ item }">
        <BaseActionMenu
          :actions="actions"
          :item="item"
        />
      </template>
    </v-data-table>
  </BaseTableLoader>
</template>

<script>
import BaseActionMenu from '../base/BaseActionMenu';
import BaseTableLoader from '../base/BaseTableLoader';
import tableMixin from '../../mixins/table-mixin';

export default {
  name: 'ProcessGroupTable',

  components: {
    BaseTableLoader,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    rows: Array,
    pagination: Object,
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      actions: [
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
      ],
      headers: [
        {
          text: this.$t('name'),
          value: 'name',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ],
    };
  },
};
</script>
