var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"expanded":_vm.tableMixin_expandedRows,"footer-props":{
    'items-per-page-options': [_vm.pagination.rowsPerPage],
  },"headers":_vm.tableMixin_displayedHeaders,"mobile-breakpoint":0,"page":_vm.pagination.page,"server-items-length":_vm.pagination.totalItems,"loading":_vm.loading,"items":_vm.rows,"height":_vm.height,"fixed-header":_vm.fixedHeader,"dense":_vm.dense,"disable-sort":""},on:{"click:row":_vm.tableMixin_onRowClick,"update:page":_vm.tableMixin_changePage},scopedSlots:_vm._u([{key:"item.order_part_no",fn:function(ref){
  var item = ref.item;
return [(item.order)?_c('a',{staticClass:"link",attrs:{"href":(_vm.ordersUrl + "?search=" + (item.order.order_no) + "&orderPartId=" + (item.id)),"target":"_blank"}},[_vm._v(_vm._s(item.order.order_no)+"."+_vm._s(item.order_part_no))]):_vm._e()]}},{key:"item.client_contact_id",fn:function(ref){
  var item = ref.item;
return [(item.order && item.order.client)?_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","nudge-bottom":"8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('span',_vm._g({staticClass:"link"},on),[_vm._v(" "+_vm._s(item.order.client.name)+" ")])]}}],null,true)},[_c('ContactCard',{attrs:{"contact-id":item.order.client.id}})],1):_vm._e()]}},{key:"item.status",fn:function(ref){
  var item = ref.item;
return [_c('v-chip',{staticClass:"persist-opacity font-weight-medium",attrs:{"color":_vm.processStatuses[item.status].color,"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t(item.status))+" ")])]}},{key:"expanded-item",fn:function(ref){
  var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.client_contact_id",fn:function(){return [(item.order && item.order.client)?_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","nudge-bottom":"8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('span',_vm._g({staticClass:"link"},on),[_vm._v(" "+_vm._s(item.order.client.name)+" ")])]}}],null,true)},[_c('ContactCard',{attrs:{"contact-id":item.order.client.id}})],1):_vm._e()]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }