import http from './http';
import { jsonToFormData } from '../util/forms';

const endpoint = 'api/contacts';

const contactService = {
  model: 'contact',

  getPage: (page, params) => http.get(`${endpoint}?page=${page}`, { params }),

  getById: id => http.get(`${endpoint}/${id}`),

  search: query => http.get(`${endpoint}/find/${query}`),

  searchClients: query => http.get(`${endpoint}/find/${query}?is_client=true`),

  searchSuppliers: query => http.get(`${endpoint}/find/${query}?is_supplier=true`),

  create: contact => http.post(`${endpoint}`, jsonToFormData(contact)),

  update: contact => http.post(`${endpoint}/${contact.id}`, jsonToFormData(contact, true)),

  delete: contact => http.delete(`${endpoint}/${contact.id}`),
};

export default contactService;
